import { AlertContext, Builder_, Dates_, Dropdown_, Input_, Slider_, Textarea_, Toggle_, Toggle_Stat } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';

export const Fake_Pocket_Agency = (props) => {
    const {t} = useTranslation();


    console.log('props.pocketAgencyInfo',props.pocketAgencyInfo);
    
      
  return (
    // <Slider_ 
    //     title={t("Fake pocket agency")} 
    //     text={t("Add")}
    //     onClose = {()=>props.closeSlider()}
    //     onClick = {() => props.onSave()}
    //     grey={t("Cancel")}
    //     iconGreyLeft="Xicon"
    //     onGrey= {() => props.closeSlider()}
    // >   
    <div>

        {/* <Builder_ title={t('Manage  Pocket Agency Subscription')}>
            <Toggle_Stat  text='Pocket Agency Subscriber' active={pocketAgencyInfo?.hidden} onClick={()=>setPocketAgencyInfo({...pocketAgencyInfo, hidden: !pocketAgencyInfo?.hidden})}/>    
        </Builder_> */}
        <Builder_ title={t('Set subscription price')}>
            <Input_ 
                placeholder={t('Price')}
                defaultValue={props.pocketAgencyInfo?.amountPaid ? props.pocketAgencyInfo?.amountPaid/100 : null} 
                onChange={(e)=> props.setPocketAgencyInfo({...props.pocketAgencyInfo, amountPaid: e.target.value})} />
        </Builder_>
        {/* <Builder_ title={t('Subscription interval')}>
            <Dropdown_>
                <select
                    value={pocketAgencyInfo.interval_count === 30 ? '30' : pocketAgencyInfo?.interval}
                    onChange={(e) => {e.target.value === '30' ? setPocketAgencyInfo({interval_count:30, interval: 'day'} ) : setPocketAgencyInfo({interval_count: 1, interval:e.target.value})}}
                >   
                    <option value='30'>{t("30 days")}</option>
                    <option value='month'>{t("Monthly")}</option>
                    <option value='year'>{t("Yearly")}</option>
                    <option value='day'>{t("Daily")}</option>
                    <option value='week'>{t("Weekly")}</option>
                    
                </select>
            </Dropdown_>    
        </Builder_> */}
           <Builder_ title={t('Select revenue type')}>
            <Dropdown_>
                <select
                   value={props.pocketAgencyInfo.revenueType}
                   onChange={(e) => { props.setPocketAgencyInfo({...props.pocketAgencyInfo,revenueType:e.target.value})}}
                >   
                    <option value="">--Revenue--</option>
                    <option value='Digital Marketing'>{t("Digital Marketing")}</option>
                    <option value='Web Project'>{t("Web Project")}</option>
                    <option value='Web Maintenance'>{t("Web Maintenance")}</option>
                    <option value='Graphic Design'>{t("Graphic Design")}</option>  
                </select>
            </Dropdown_>    
        </Builder_>


        <Builder_ title={t('When does the subscription end?')}>
    
            <Dates_
                allowPartialRange={false}
                onChange={(date) => {
                  
                    props.setPocketAgencyInfo((prevState) => ({
                        ...prevState,
                        end: date // Update end timestamp
                    }));
                }}
                value={Number.isInteger(props.pocketAgencyInfo?.end) && props.pocketAgencyInfo?.end > 0 && props.pocketAgencyInfo?.end < 32503680000 ? new Date(props.pocketAgencyInfo?.end * 1000) : props.pocketAgencyInfo?.end}
                // Convert Unix timestamp (in seconds) to Date object
                selectRange={false}
                dateFullCellRender={(date) => date.getDate()} // Render day of the month
                minDate={new Date()} // Prevent past dates
            />
        </Builder_>
        <Builder_ title={t('Add description (optional)')}>
            <Textarea_ label={t('Description')} defaultValue={props.pocketAgencyInfo?.description} onChange={(e)=> props.setPocketAgencyInfo({...props.pocketAgencyInfo, description: e.target.value})} />
        </Builder_>
    </div>

    // </Slider_>
  )
}
