import { Card, device, Main_Plain, Red_, Slider_ } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get_invoices } from '../../../../services/api/Mocks';
import styled from 'styled-components';
import { Invoice_Card } from '../../../partials/invoiceCard/Invoice_Card';




export const Dailyprofit_Subscriber_Invoices = (props) => {
    const {t} = useTranslation();
    const [invoices, setInvoices] = useState([]) 

    const getInvoices = (payload)=>{
        get_invoices(payload, props?.dateFilters)
        .then(res =>{
            setInvoices(res?.data?.data)    
        }
    )}



    useEffect(() => {

      let payload = {
        userId:props.userId,
        questId: props.selectedQuest?.questId,
        category:'dailyprofit'
      };

      getInvoices(payload, props?.dateFilters);
    }, []);
    console.log('invoices', invoices);
    console.log('selectedQuest', props.selectedQuest);
    
    

  return (
    <Slider_
        title={t("Invoices")} 
        //text={t("I wish to continue")} 
        //  icon="Next" 
        onClick={()=>props.setSelectedQuest(false)}
        grey={t("Cancel")}
        icon="Check"
        iconGreyLeft="Xicon"
        onClose={()=>props.setSelectedQuest(false)}
        onGrey={()=>props.setSelectedQuest(false)}
        text={t("Done")}
    >
        {invoices?.length && invoices?.map((invoice, i) => (
          <Invoice_Card
            amountPaid={
              !invoice?.fakeInvoice && props.selectedQuest?.tax && props.selectedQuest.tax !== 0
                ? (invoice?.amountPaid / 100) / props.selectedQuest?.tax
                : (invoice?.amountPaid / 100)  // Or a fallback value like 0
              }
              onEdit={()=> invoice?.fakeInvoice ? true : false}
            
            >
            <p>{t("Period")}:</p>
            <h5>{new Date(invoice?.start * 1000).toLocaleDateString('el-GR')} - {new Date(invoice?.end * 1000).toLocaleDateString('el-GR')}</h5>
            {invoice?.status ? 
              <>
                <p>{t("Status")}:</p>
                <h5>{invoice?.status}</h5>
              </>
            :null}
          </Invoice_Card>
          
        ))}
    </Slider_>
  )
}
