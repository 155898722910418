import { Card, device, Main_Plain, Red_ } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const InvoiceCard = styled(Card)`
  background: ${p => p.theme.mid};
  padding: 1rem 2rem;

  margin-bottom: 2rem;
  display: flex;
  cursor: ${p=> p.clickable ? 'pointer' : 'default' };
  align-items: start;
  justify-content: space-between;
`

const InvoiceCardLeft = styled.div`
    width: 100%;
  `

const InvoiceCardAmountPaid = styled.div`

display: flex;
align-items: baseline;
gap: 0.5rem;
@media ${device.sm}{
  flex-direction: column;
  gap: 0;
}
`
const InvoiceCardAmountPaidInner = styled.div`
display: flex;
align-items: baseline;
h1{
  font-size: 4rem;
  text-align: center;
}
`

const InvoiceCardGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;

  padding: 1rem 2rem;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }

  @media ${device.md} {
    grid-template-columns: auto 1fr;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
`

const InvoiceCardRight = styled.div`
  display: flex;
  align-items: center;
`
const InvoiceLabel = styled.div`
    border-radius: 0.4rem;
   
   background-color: #F6DFF2;
   border: 1px solid;
   border-color: var(--focus);


   h5{
       white-space: nowrap;

       letter-spacing: 0.5px;
       padding: 0 .5rem;
       color:var(--focus);
          
       
   }
`
const InvoiceLabelWrapper= styled.div`
   display: flex;
`
export const Invoice_Card = (props) => {
    const {t} = useTranslation();
  return (
    <InvoiceCard clickable={props?.onClick? true : false}>
      <InvoiceCardLeft onClick={props.onClick}>
      <InvoiceLabelWrapper>
      {props?.label ? 
            <InvoiceLabel>
              <h5>{props?.label}</h5>
            </InvoiceLabel>
          : null}
      </InvoiceLabelWrapper>
         
          <InvoiceCardAmountPaid>
            <InvoiceCardAmountPaidInner>
                <h1>€{(Number(props?.amountPaid) || 0).toFixed(2)}</h1>
                {/* <h5>,00</h5> */}
            </InvoiceCardAmountPaidInner>
          {/* <h5>(VAT incl.)</h5> */}
          </InvoiceCardAmountPaid>
          <InvoiceCardGrid>
            {props.children}
          </InvoiceCardGrid>
        </InvoiceCardLeft>
        <InvoiceCardRight>
          {props.canDelete ? <Red_ iconLeft="Delete" onClick={props.onDelete} /> : null}
          {props.canEdit && <Main_Plain iconLeft="Edit" onClick={props.onEdit} />}
      </InvoiceCardRight>
    </InvoiceCard>
  )
}
