import { Alert, Alert_, AlertContext, Builder_, Card, CardsForty, CardsSixty, device, Dropdown_, Grey_Link, logo, Main_Plain, On_Click_Card, PageAlwaysVisible, PageForty, PageFull, PageSixty, PageSubtitle, PageTitle, Section_Title, Slider_, ThemeContext } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { create_fake_invoice, create_fake_questad_invoice, create_fake_questad_sub, create_fake_sub, get_subscriber_info } from '../../../services/api/Mocks';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Graph_Box from '../../partials/graphBox/Graph_Box';
import { parseISO, differenceInMonths, addMonths, format, compareAsc } from "date-fns";
import { get_meta_adspend, get_total_revenue, get_user_total_revenue } from '../../../services/api/Subscriptions';
import { Fake_Pocket_Agency } from '../../partials/fakePocketAgency/Fake_Pocket_Agency';
import Fake_Dailyprofit from '../../partials/fakeDailyprofit/Fake_Dailyprofit';


const Subscriber = styled.div`
  .AdsnapCard{
    pointer-events: none;
  }
  
`

const SubcriptionWrapper = styled.div`
  border-top: 1px solid var(--grey);
  padding-top: 1rem;
`
const SubscriberSubtitle = styled.div` 
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  
`


const SubscriberInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  gap:1rem;
`
const SubscriberInfoUser = styled.div`
  border-radius: var(--smallRadius);
  background: ${p=>p.theme.mid};
  padding: var(--smallRadius);
`

export default function SUBSCRIBER() {
    const {t} = useTranslation();
    const params = useParams();

    // console.log('userId', userId);
    const {theme} = useContext(ThemeContext);
    const navigate = useNavigate();
    

    const [addPocketAgency, setAddPocketAgency] = useState(false)
    const [addSubscription, setAddSubscription] = useState(false)
    const [editPocketAgency, setEditPocketAgency] = useState(false)
    const [subType, setSubType] = useState('questad')

    const [pocketAgencyInfo, setPocketAgencyInfo] = useState('')
    const [dailyprofitInfo, setDailyprofitInfo] = useState({tax:true})
    const [subscriber, setSubscriber] = useState('')
    const [revenue, setRevenue] = useState(false)

  
    const hasDailyprofitSub = subscriber?.subs?.some(sub=> sub?.category === 'dailyprofit');
    const hasLoisSub = subscriber?.subs?.some(sub=> sub?.category === 'lois');
    const hasBondSub = subscriber?.subs?.some(sub=> sub?.category === 'bond');
    const pocketAgencySubId = subscriber?.subs?.find(sub=> sub?.category === 'bond')?._id;
    const bondSubId = subscriber?.subs?.find(sub=> sub?.category === 'bond' && sub?.fakeSub)?._id;
    const hasSub = subscriber?.subs?.some(sub=> sub?.fakeSub);
    // const quests = subscriber?.subs?.filter(sub=> sub?.category === 'dailyprofit');
    const { alerts, setAlerts } = useContext(AlertContext);
  

    // const fetchSubscriberInfo = (payload) => {
    const [kpis, setKPIs] = useState(false)
    const fetchSubscriberInfo = async() => {
  
        get_subscriber_info({userId: params?.id}).then(async(res) => {

          console.log('RESSS get_subscriber_info', res);
          
           setSubscriber({...subscriber, subs: res?.data?.subs, info: res?.data?.user});

            let customer = res?.data?.subs[0]?.cusId;
            let subIds = res?.data?.subs?.map(sub => sub.subId);
            console.log('subIds', subIds);
            
            let revenue = await get_total_revenue(customer);
        
            setKPIs({totalRevenue: revenue?.totalRevenue, 
                    totalFee: revenue?.totalFee,
                    totalMaxAdspend: revenue?.totalExpectedAdSpend,
                    kpis: revenue?.kpis
        
          })  
          });
    };


  //   const create_pocket_agency_sub = () => {
  //     let data = {
  //         userId: params?.id,
  //         amount: pocketAgencyInfo?.price ,
  //         current_period_end: pocketAgencyInfo?.date,
  //         description: pocketAgencyInfo?.description,
  //     };

  //     console.log('CALLED');
      
  //     create_fake_sub(data)
  //       .then((res) => {
  //         console.log('RESsssssssssssssssssssssssssssssssssss', res);
  //          if (res?.data?.code ==='S2004'){ 
  //             fetchSubscriberInfo();
  //             setAlerts([...alerts, { message: { text: 'Pocket agency subscription created successfully!', status: "Success"}} ])
  //             setAddPocketAgency(false)
  //          }else{
  //             setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
  //          }
  //         })
  //       .catch((err) => {});
  // };

  const createFakeInvoice = () => {
    let data = {
        userId: subscriber?.info?.userId,
        customerEmail: subscriber?.info?.username,
        amount: pocketAgencyInfo?.amountPaid ,
        end: pocketAgencyInfo?.end,
        description: pocketAgencyInfo?.description,
    };

    
    create_fake_invoice(data)
      .then((res) => {
         if (res?.data?.code ==='I2002'){ 
            fetchSubscriberInfo();
            setAlerts([...alerts, { message: { text: 'Pocket agency subscription created successfully!', status: "Success"}} ])
            setAddSubscription(false)
         }else{
            setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
         }
        })
      .catch((err) => {});
};


  // const createFakeQuestadInvoice = () => {
  //   let data = {
  //       userId: subscriber?.info?.userId,
  //       customerEmail: subscriber?.info?.username,
  //       amountPaid: dailyprofitInfo?.amountPaid ,
  //       start: dailyprofitInfo?.start,
  //       end: dailyprofitInfo?.end,
  //       description: dailyprofitInfo?.description,
  //   };

    
  //   create_fake_questad_invoice(data)
  //     .then((res) => {
  //       console.log('RESsssssssssssssssssssssssssssssssssss', res);
  //       if (res?.data?.code ==='I2002'){ 
  //           fetchSubscriberInfo();
  //           setAlerts([...alerts, { message: { text: 'Questad invoice created successfully!', status: "Success"}} ])
  //           // setAddSubscription(false)
  //       }else{
  //           setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
  //       }
  //       })
  //     .catch((err) => {});
  // };

  
  const createFakeQuestadSub = () => {
    let data = {
        userId: subscriber?.info?.userId,
        customerEmail: subscriber?.info?.username,
        amountPaid: dailyprofitInfo?.amountPaid ,
        start: dailyprofitInfo?.start,
        end: dailyprofitInfo?.end,
        tax:  dailyprofitInfo?.tax ?  1.24 : '',
        description: dailyprofitInfo?.description,
    };
    
    create_fake_questad_sub(data)
      .then((res) => {
        if (res?.data?.code ==="S2004"){ 
            fetchSubscriberInfo();
            setAlerts([...alerts, { message: { text: 'Questad invoice created successfully!', status: "Success"}} ])
            setAddSubscription(false)
            setDailyprofitInfo([])
        }
        else if (res?.data?.code ==="DRAFT-4000") {
            setAlerts([...alerts, { message: { text: 'Subscription cannot be started without a draft Quest. Please go to Questad and create a draft Quest.', status: "Error"}} ])  
        }
        else{
            setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
        }
        })
      .catch((err) => {});
  };


  const getRevenue = async() => {
    let revenue = await get_user_total_revenue(params?.id);
    setRevenue(revenue)
  }
  

  useEffect(() => {
    fetchSubscriberInfo();
  }, []);


  
  // const uncoveredMonthsCount = calculateUncoveredMonths(subscriber?.subs?.filter(sub=> sub?.category === 'lois'))
  console.log('pocketAgencySubId', pocketAgencySubId);
  console.log('K P I s', kpis);
  console.log('subscriber', subscriber );
  console.log('pocketAgencyInfo!!!!!!!!!!!!!!!!!!!', pocketAgencyInfo);
  
  

    useEffect(() => {
      getRevenue();
    }, []);

    
  return (
    <Subscriber>
      <Alert_ duration={6000}/>
          {addPocketAgency ? 
            <Fake_Pocket_Agency
              closeSlider={()=>setAddPocketAgency(false)}
              pocketAgencyInfo={pocketAgencyInfo}
              setPocketAgencyInfo={setPocketAgencyInfo}
              onSave={createFakeInvoice}
            />
          : null}
          {addSubscription ? 
          <Slider_ 
              title={t("Subscription")} 
              text={t("Add")}
              onClose = {()=> setAddSubscription(false)}
              onClick = {() => subType==='pocketagency' ? createFakeInvoice() : createFakeQuestadSub()}
              grey={t("Cancel")}
              iconGreyLeft="Xicon"
              onGrey= {() => setAddSubscription(false)}
            >   
              <div>
                <Builder_ >
                  <Dropdown_ label={t('Subscription type')} >
                      <select
                        value={subType}
                        onChange={(e) => setSubType(e.target.value)}
                      >   
                        <option value='questad'>{t("Questad")}</option>
                        <option disabled={hasBondSub} value='pocketagency'>{t("Pocket Agency")}</option>
                      </select>
                  </Dropdown_>    
                </Builder_>
                <SubcriptionWrapper>
                  {subType === 'pocketagency' ? 
                    <Fake_Pocket_Agency
                      closeSlider={()=> setAddSubscription(false)}
                      pocketAgencyInfo={pocketAgencyInfo}
                      setPocketAgencyInfo={setPocketAgencyInfo}
                      // onSave={createFakeInvoice}
                    />
                  : 
                    <Fake_Dailyprofit
                      dailyprofitInfo = {dailyprofitInfo}
                      setDailyprofitInfo={setDailyprofitInfo}
                    />
                  }
                </SubcriptionWrapper>
              </div>
            </Slider_>
          : null}
         <PageFull>
        <PageTitle>
          <Grey_Link noPadding iconLeft='Back' onClick={()=>navigate(`/${params.lang}/akihiko/griphook/subscribers`)}/>
          <h1>{subscriber?.info?.firstname} {subscriber?.info?.lastname}</h1>
        </PageTitle>
      </PageFull>
      <PageSixty>
      <PageAlwaysVisible>
        <SubscriberInfo>
          <SubscriberInfoUser>
          <h6>{subscriber?.info?.username} </h6>
          </SubscriberInfoUser>
          <p>Created at: {new Date(subscriber?.info?.createdAt).toLocaleDateString('el-GR')} </p>
        </SubscriberInfo>
        {/* <h3>User stats</h3> */}
          <CardsForty>
            <Graph_Box 
              kpiTitle={t('Expected recurring revenue next month')}
              kpiValue={'Coming soon'}
              // kpiTitle={'Total revenue'}
              // kpiValue={'€ '+ (kpis?.totalRevenue ? kpis?.totalRevenue.toFixed(2) : 'Loading...')}
            />
            <Graph_Box 
              kpiTitle={'Total recurring revenue'}
              kpiValue={'€ ' + (kpis?.totalRevenue ? kpis?.totalRevenue.toFixed(2) : 'Loading...')}
            /> 
          </CardsForty>
          <SubscriberSubtitle>
            <h3>{t('Subscriptions')}</h3>
            {/* {!hasBondSub ?   */}
              {/* <Main_Plain text='Subscription' onClick={()=> setAddSubscription(true)} noPadding iconLeft='Add' /> */}
            {/* : null}   */}
          </SubscriberSubtitle>
        <CardsForty>
        {hasDailyprofitSub ? 
          <On_Click_Card
            imgSmall={logo.dailyprofit[theme]}
            title='Questad'
            onClick={()=> navigate(`/${params.lang}/akihiko/griphook/subscribers/questad/${params.id}`)}
          /> : null}
        {hasLoisSub ? 
          <On_Click_Card
            imgSmall={logo.lois[theme]}
            title='Adsnap'
            onClick={()=> navigate(`/${params.lang}/akihiko/griphook/subscribers/adsnap/${params.id}`)}
          />
         : null} 
        {hasBondSub ? 
          <On_Click_Card
            imgSmall={logo.bond[theme]}
            title='Pocket Agency'
            onClick={()=> navigate(`/${params.lang}/akihiko/griphook/subscribers/pocket-agency/${params?.id}`)}
          />
          : null}

          <On_Click_Card
            icon="Add"
            title={t('Subscription')}
            onClick={()=> setAddSubscription(true)}          
          />
         </CardsForty>
      </PageAlwaysVisible>
    </PageSixty>
    </Subscriber>
  )
}

