
import { Builder_, Dates_, Input_, Textarea_, Toggle_Stat } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
 
const FakeDailyprofit = styled.div`
`

const FakeDailyprofitSplit = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap:1rem;
` 
export default function Fake_Dailyprofit(props) {
 
    const {t} = useTranslation()
 
    const onChange = (selections) => {
      // props.onDates(selections)
      props.setDailyprofitInfo({...props.dailyprofitInfo, start: selections[0],end: selections[1], renderedDates: selections})
    }
    console.log('dailyprofitInfo!!!!!!!!!!!', props.dailyprofitInfo);
    
  return (
    <FakeDailyprofit>
        <Builder_>
        
        </Builder_>
        <Builder_ title={t('Price')}>
          <FakeDailyprofitSplit>
            <Input_ 
              placeholder={t('Price')} 
              defaultValue={props.dailyprofitInfo?.amountPaid ? props.dailyprofitInfo?.amountPaid/100 : null} 
              onChange={(e)=> props.setDailyprofitInfo({...props.dailyprofitInfo, amountPaid: e.target.value})} 
            />
            <Toggle_Stat 
              text={t('Price includes tax')} 
              active={props.dailyprofitInfo?.tax} 
              onClick={()=>props.setDailyprofitInfo({...props.dailyprofitInfo, tax: !props.dailyprofitInfo?.tax})}
            />
          </FakeDailyprofitSplit>
        </Builder_>
 
          <Builder_ title={t('Dates covered by the invoice')}>
            <Dates_
              allowPartialRange={true}
              onChange={onChange} 
              value={
                props.dailyprofitInfo?.renderedDates || 
                [props.dailyprofitInfo?.start ? new Date(props.dailyprofitInfo.start * 1000) : null, 
                 props.dailyprofitInfo?.end ? new Date(props.dailyprofitInfo.end * 1000) : null]
              }
              selectRange={true} 
              dateFullCellRender={(date) => date?.date()} 
            />
        </Builder_>

        <Builder_ title={t('Add description (optional)')}>
            <Textarea_ label={t('Description')} defaultValue={props.dailyprofitInfo?.description} onChange={(e)=> props.setDailyprofitInfo({...props.dailyprofitInfo, description: e.target.value})} />
        </Builder_>
    </FakeDailyprofit>
  )
}