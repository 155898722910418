import { Alert_, AlertContext, Confirmation_, device, Grey_Link, Main_Plain, PageAlwaysVisible, PageSixty, PageTitle, Slider_ } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react'
import { create_fake_questad_invoice, delete_fake_invoice, edit_fake_questad_invoice, get_invoices, get_subscriber_info } from '../../../services/api/Mocks';
import { useTranslation } from 'react-i18next';
import { Invoice_Card } from '../../partials/invoiceCard/Invoice_Card';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Fake_Dailyprofit from '../../partials/fakeDailyprofit/Fake_Dailyprofit';
import Dailyprofit_Invoices_History from './partials/Dailyprofit_Invoices_History';


const SubscriberInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  gap:1rem;
`
const SubscriberInfoUser = styled.div`
  border-radius: var(--smallRadius);
  background: ${p=>p.theme.mid};
  padding: var(--smallRadius);
  display: flex;
  align-items: center;
  gap: .5rem;
  @media ${device.sm}{
    flex-direction: column;
    width: 100%;

  }
`

const SubscriberActions = styled.div`
    /* display: flex;
    justify-content: right;
    padding-bottom: 1rem; */
    border: 1px dashed var(--greyDark);
    background: ${p=>p.theme.mid};
    padding: var(--largePads);
    margin: 2rem 0;
    border-radius: var(--smallRadius);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
`

 
export default function DAILYPROFIT_INVOICES() {
    const {t} = useTranslation();
    const [invoices, setInvoices] = useState([]) 
    const {id, questId, lang} = useParams();
    const [subscriber, setSubscriber] = useState('')
    
    const [newInvoice, setNewInvoice] = useState(false)
    const [editInvoice, setEditInvoice] = useState(false)
    const [dltInvoice, setDltInvoice] = useState(false)
    const [viewHistory, setViewHistory] = useState(false)
    const { alerts, setAlerts } = useContext(AlertContext);

    const navigate = useNavigate();

    const fetchSubscriberInfo = async() => {
  
      get_subscriber_info({userId: id}).then(async(res) => {

        console.log('RESSS get_subscriber_info', res);
        
         setSubscriber({...subscriber, subs: res?.data?.subs, info: res?.data?.user});

          let customer = res?.data?.subs[0]?.cusId;
          let subIds = res?.data?.subs?.map(sub => sub.subId);
          console.log('subIds', subIds);
         
      
        })  
        
    };


    const createFakeQuestadInvoice = () => {
      let data = {
          userId: subscriber?.info?.userId,
          customerEmail: subscriber?.info?.username,
          amountPaid: newInvoice?.amountPaid ,
          start: newInvoice?.start,
          end: newInvoice?.end,
          description: newInvoice?.description,
          subscriptionId: invoices[0]?.subscriptionId,
          customerId: invoices[0]?.customerId,
          questId: questId
      };
  
      
      create_fake_questad_invoice(data)
        .then((res) => {
          if (res?.data?.code ==="I2002"){ 
              getInvoices();
              setAlerts([...alerts, { message: { text: 'Questad invoice created successfully!', status: "Success"}} ])
              setNewInvoice(false)
          }else{
              setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
          }
          })
        .catch((err) => {});
    };

    console.log('editInvoice!!', editInvoice);



  const deleteFakeQuestadInvoice = () => {
            
        delete_fake_invoice(dltInvoice)
          .then((res) => {
            console.log('RESsssssssssssssssssssssssssssssssssss', res);
            if (res?.data?.code ==='I2003'){ 
                setDltInvoice(false)
                getInvoices();
                setAlerts([...alerts, { message: { text: 'Pocket agency invoice deleted successfully!', status: "Success"}} ])

            }else{
                setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
            }
              setDltInvoice(false)
            })
          .catch((err) => {});
    };

  const editFakeQuestadInvoice = () => {
    let originalInvoice = invoices.find(inv => inv._id === editInvoice._id);

    if (!originalInvoice) {
        console.error("Original invoice not found");
        return;
    }

    // Filter only the changed fields
    let updatedFields = Object.keys(editInvoice).reduce((acc, key) => {
        if (editInvoice[key] !== originalInvoice[key]) {
            acc[key] = editInvoice[key];
        }
        return acc;
    }, {});

    // Ensure we always send userId & invoiceId
    updatedFields.userId = editInvoice.userId;
    updatedFields.invoiceId = editInvoice._id;

    console.log("Updated fields:", updatedFields);

    edit_fake_questad_invoice(updatedFields)
        .then((res) => {
            console.log('Response:', res);
            if (res?.data?.code === "I20025") { 
                setEditInvoice(false);
                getInvoices();
                setAlerts([...alerts, { message: { text: 'Invoice updated successfully!', status: "Success"} }]);
            } else {
                setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"} }]);
            }
        })
        .catch((err) => {
            console.error("Error updating invoice:", err);
        });
};

  
    
    const getInvoices = ()=>{

      let payload = {
        userId: id,
        questId: questId,
        category:'dailyprofit'
      };
        // get_invoices(payload, props?.dateFilters)
        get_invoices(payload)
        .then(res =>{
            setInvoices(res?.data?.data)    
        }
    )}



    useEffect(() => {
      getInvoices();
      fetchSubscriberInfo();
      // getInvoices(payload, props?.dateFilters);
    }, []);
    console.log('invoices', invoices);

    console.log('viewHistory', viewHistory);
    
    // console.log('selectedQuest', props.selectedQuest);
  return (
    <PageSixty>
      {dltInvoice ?
          <Confirmation_
            message={t("Are you sure you want to delete this invoice?")}
            yes={t("Yes")}
            onYes={() => deleteFakeQuestadInvoice()}
            onNo={() => setDltInvoice(false)}
            no={t("No")}
          />
        : null}
      <Alert_/>
      {newInvoice ? 
          <Slider_
            title={t("Subscription")} 
            text={t("Add")}
            onClose = {()=> setNewInvoice(false)}
            onClick = {createFakeQuestadInvoice}
            grey={t("Cancel")}
            iconGreyLeft="Xicon"
            onGrey= {() => setNewInvoice(false)}
          >       
            <Fake_Dailyprofit
              dailyprofitInfo = {newInvoice}
              setDailyprofitInfo={setNewInvoice}
            />   
          </Slider_>
        : null}

        {editInvoice ? 
          <Slider_
            title={t("Subscription")} 
            text={t("Add")}
            onClose = {()=> setEditInvoice(false)}
            onClick = {editFakeQuestadInvoice}
            grey={t("Cancel")}
            iconGreyLeft="Xicon"
            onGrey= {() => setEditInvoice(false)}
          >       
            <Fake_Dailyprofit
              dailyprofitInfo = {editInvoice}
              setDailyprofitInfo={setEditInvoice}
            />   
          </Slider_>
        : null}

        {viewHistory ? 
          <Dailyprofit_Invoices_History 
            history={viewHistory?.history}
            onClose={()=>setViewHistory(false)}
          />  
        :null}
      <PageAlwaysVisible>
        <PageTitle>
          <Grey_Link iconLeft='Back' noPadding onClick={()=>navigate(`/${lang}/akihiko/griphook/subscribers/questad/${id}`)}/>
          <div>
            <h2>{t('Questad Invoices')}</h2>
          </div>
        </PageTitle>
        <SubscriberInfo>
          <SubscriberInfoUser>
            <h6>{subscriber?.info?.firstname} {subscriber?.info?.lastname} |</h6>
            <h6> {subscriber?.info?.username} </h6>
          </SubscriberInfoUser>
        </SubscriberInfo>
        <SubscriberActions>
          <Main_Plain text='Invoice' onClick={()=> setNewInvoice(true)} noPadding iconLeft='Add' />
        </SubscriberActions>

        
        {invoices?.length && invoices?.map((invoice, i) => (
            <Invoice_Card
              amountPaid={
                !invoice?.fakeInvoice && invoice?.customerTaxId?.startsWith("EL")
                ? (invoice?.amountPaid / 100) / 1.24
                : (invoice?.amountPaid / 100)  // Or a fallback value like 0
                }
                label={invoice?.fakeInvoice ? 'Custom':null}
                canEdit = {invoice?.fakeInvoice}
                canDelete = {invoice?.fakeInvoice}
                onEdit={()=>  setEditInvoice(invoice)}
                onDelete={() => setDltInvoice(invoice?._id)}
              >
              <p>{t("Period")}:</p>
              <h5>{new Date(invoice?.start * 1000).toLocaleDateString('el-GR')} - {new Date(invoice?.end * 1000).toLocaleDateString('el-GR')}</h5>
                {invoice?.status ? 
                    <>
                    <p>{t("Status")}:</p>
                    <h5>{invoice?.status}</h5>
                    </>
                :null}

                {invoice?.description ? 
                  <>
                  <p>{t("Description")}:</p>
                  <h5>{invoice?.description}</h5>
                  </>
                  
                :null}
                {invoice?.fakeInvoice ?
                  <>
                    <p>{t("Last update")}:</p>
                    <Main_Plain 
                      noPadding
                      text={invoice?.history?.length > 0 
                        ? new Date(invoice.history[invoice.history.length - 1].createdAt).toLocaleDateString('el-GR')
                        : "No history available"}
                      iconRight={invoice?.history?.length ?'Next'  : null}
                      onClick={()=> invoice?.history?.length > 0 ? setViewHistory(invoice) : null}
                    />
                    </>
                :null}
     

            </Invoice_Card>
            
        ))}
        </PageAlwaysVisible>
    </PageSixty>
  )
}
