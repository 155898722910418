import { Card, Slider_ } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'


const HistoryCard = styled(Card)`
  background: ${p => p.theme.mid};
  padding: 1rem 2rem;

  margin-bottom: 2rem;
  /* display: flex;
  cursor: ${p=> p.clickable ? 'pointer' : 'default' };
  align-items: start;
  justify-content: space-between; */
`

export default function Dailyprofit_Invoices_History(props) {
    const {t} = useTranslation()

    console.log('props.history', props.history);
    
  return (
    <Slider_
        title={t("History")} 
        text={t("Close")}
        onClose = {props.onClose}
        onClick = {props.onClose}
    >       
    {props.history
        ?.slice() // Create a shallow copy to avoid modifying the original array
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort in descending order
        .map((history, i) => (
            <HistoryCard key={i}>
                <h4>{new Date(history?.createdAt).toLocaleDateString('el-GR')} | {history?.action}</h4>
                <p>{history?.admin?.adminName}</p>
            </HistoryCard>
        ))}
    
    </Slider_>
  )
}
