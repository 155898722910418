import axios from 'axios';
const protocol = process.env.REACT_APP_ERISED_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_ERISED_BACKEND_HOST;
const port = process.env.REACT_APP_ERISED_BACKEND_PORT;
const erisedURL = `${protocol}${url}${port}`


const d_protocol = process.env.REACT_APP_DAILYPROFIT_BACKEND_PROTOCOL;
const d_url = process.env.REACT_APP_DAILYPROFIT_BACKEND_HOST;
const d_port = process.env.REACT_APP_DAILYPROFIT_BACKEND_PORT;
const dailyprofitURL = `${d_protocol}${d_url}${d_port}`

const g_protocol = process.env.REACT_APP_GRIPHOOK_BACKEND_PROTOCOL;
const g_url = process.env.REACT_APP_GRIPHOOK_BACKEND_HOST;
const g_port = process.env.REACT_APP_GRIPHOOK_BACKEND_PORT;
const griphookURL = `${g_protocol}${g_url}${g_port}`

// admin level
const get_subscribers = (options) => {
    return new Promise((resolve, reject) => {

        let dummyOptions = {
            "elastic": "Manestr",
            "filters": {
                "firstname": "Kritharis",
                "lastname": "Manestros"
            },
            "dateStart": "2023-01-01",
            "dateEnd": "2023-12-31",
            "currentPage": 0,
            "pageSize": 2,
            "sorting": {
                "firstname": 1
            }
        }

        console.log('DATA OPTIONS', {data: options}, options);
        
       
        axios.post(`${griphookURL}/subscriptions/getAllSubscribers`, {data: options})
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const get_subscriptions = (options) => {


    return new Promise((resolve, reject) => {
       
        let params = `${options?.filters ? "filters="+encodeURIComponent(JSON.stringify(options.filters))+"&" : ""}`+
                        `${options?.elastic ? "elastic="+options.elastic+"&" : ""}`+
                        `${options?.sorting ? "sorting="+encodeURIComponent(JSON.stringify(options.sorting))+"&" : ""}`+
                        `${options?.pagination ? "pagination="+options.pagination+"&" : ""}`

      
        axios.get(`${erisedURL}/subscriptions/getSubscriptions?${params}`)
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
            })
    })
}


const get_plans = () => {

    return new Promise((resolve, reject) => {
        axios.get(`${griphookURL}/plans/getProducts`, {
            headers:{"x-griphook-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"}
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const plan_exists = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/plans/planExists`, {
            headers:{"x-griphook-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"},
            plan: data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const get_my_subscriptions = (userId) => {

    return new Promise((resolve, reject) => {
        axios.get(`${erisedURL}/subscriptions/getMySubscriptions?userId=${userId}`)
        .then(res => resolve(res))
        .catch(err=> resolve(false))
    })
}

const create_plan = (priceModel) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/plans/createProduct`,{
            data: priceModel
        })
        .then(res=> {
            resolve(res)
        })
        .catch(err=>{
            resolve(err)
        })
    })
}

const delete_plan = (planId) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/plans/deleteProduct`,{
            headers:{"x-griphook-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"},
            plan: planId
        })
        .then(res=> {
            resolve(res)
        })
        .catch(err=>{
            resolve(err)
        })
    })
}

// user level

const plan_subscribers = (product_priceId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${erisedURL}/subscriptions/getSubscription?=${product_priceId}`, {
            headers:{"x-erised-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"}
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const send_metadata_fields = (metadata) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/checkout/checkout`, {
            metadata
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}
const create_portal_session = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/checkout/editSubscription`, {
            payload
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const send_payment_link = (email, lang, paymentLink, planPrice) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/checkout/sendPaymentLink`, {
            email,
            lang,
            paymentLink,
            planPrice
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const send_metadata_fields_dummy = (metadata) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/checkout/cartCheckout`, {
            metadata
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


// const get_subscriber_info = ({ startDate, endDate, userId }) => {

//     return new Promise((resolve, reject) => {
//         axios.get(`${erisedURL}/subscriptions/getSubscriberInfo?userId=${userId}`)
//         .then(res => resolve(res))
//         .catch(err=> resolve(false))
//     })
// }


const get_subscriber_info = ({ startDate, endDate, userId }) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${erisedURL}/subscriptions/getSubscriberInfo`,
                {
                    params: {
                        userId,
                        startDate,
                        endDate,
                    },
                }
            )
            .then((res) => resolve(res))
            .catch((err) => resolve(false));
    });
};



const get_quest_info = (questId, cusId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${dailyprofitURL}/quests/getQuest?id=${questId}&cusId=${cusId}`,
            )
            .then((res) => resolve(res))
            .catch((err) => resolve(false));
    });
};


const create_fake_sub = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/subscriptions/createFakeSub`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


const edit_fake_sub = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/subscriptions/editFakeSub`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}



const delete_fake_sub = (id) => {
    console.log('id', id);

    return new Promise((resolve, reject) => {
        axios.get(`${griphookURL}/subscriptions/deleteFakeSub?id=${id}`)
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const get_plan_details = (id) => {
    
    return new Promise((resolve, reject) => {
        axios.get(`${griphookURL}/subscriptions/getPlanDetails?id=${id}`)
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const get_all_active_products = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/subscriptions/getAllActiveProducts`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


const search_active_products = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/subscriptions/searchActiveProducts`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const edit_pocket_agency_sub = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/subscriptions/editPocketAgencySub`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


const get_invoices = (payload, dateFilters) => {
    
    return new Promise((resolve, reject) => {

        let data = {
            data: payload
        }

        if (dateFilters?.startDate && dateFilters?.endDate) data.dateFilters = dateFilters;
       
        axios.post(`${griphookURL}/invoices/getInvoices`, data )
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


const create_fake_questad_sub = (data) => {
    console.log('DATA-----------------', data);
    
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/subscriptions/createFakeQuestadSub`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}



const create_fake_questad_invoice = (data) => {
    console.log('DATA-----------------', data);
    
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/invoices/createFakeQuestadInvoice`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}



const edit_fake_questad_invoice = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/invoices/editFakeQuestadInvoice`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


const delete_fake_questad_invoice = (invoiceId) => {

    return new Promise((resolve, reject) => {
        axios.get(`${griphookURL}/invoices/deleteFakeInvoice?invoiceId=${invoiceId}`)
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const create_fake_invoice = (data) => {
    console.log('DATA-----------------', data);
    
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/invoices/createFakeInvoice`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


const delete_fake_invoice = (id,userId,subId) => {

    return new Promise((resolve, reject) => {
        axios.get(`${griphookURL}/invoices/deleteFakeInvoice?id=${id}&userId=${userId}&subId=${subId}`)
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const edit_fake_invoice = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/invoices/editFakeInvoice`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


const edit_pocket_agency_flow = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/subscriptions/editPocketAgencyFlow`, {
           data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}


const repairQuest = (payload) => {

    // const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        // let data = {
        //  questId:questId,
        // }

        axios.post(`${dailyprofitURL}/adminPanel/repairQuest`, payload)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('REPAIR QUEST');
            resolve(false);
        })
    })
}

const repairMetaAdspend = (quests) => {

    // const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        let data = {
            quests: quests,
        }

        axios.post(`${dailyprofitURL}/adminPanel/repairMetaAdspend`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('REPAIR QUEST');
            resolve(false);
        })
    })
}

export {
    get_subscribers,
    get_subscriptions,
    get_plans,
    delete_plan,
    create_plan,
    plan_subscribers,
    send_metadata_fields,
    create_portal_session,
    send_payment_link,
    get_my_subscriptions,
    plan_exists,
    send_metadata_fields_dummy,
    get_subscriber_info,
    create_fake_sub,
    edit_fake_sub,
    delete_fake_sub,
    get_plan_details,
    get_all_active_products,
    search_active_products,
    edit_pocket_agency_sub,
    get_quest_info,
    get_invoices,
    create_fake_questad_sub,
    create_fake_questad_invoice,
    edit_fake_questad_invoice,
    delete_fake_questad_invoice,
    create_fake_invoice,
    delete_fake_invoice,
    edit_fake_invoice,
    edit_pocket_agency_flow,
    repairQuest,
    repairMetaAdspend
};